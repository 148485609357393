import React from 'react';

//	S T Y L E

import './com-vehicle-gallery-edit.scss'

//	T Y P E S

import { props___ComVehicleGalleryEditComponent, state___ComVehicleGalleryEditComponent, state___ComVehicleGalleryEditComponent___defaults } from './com-vehicle-gallery-edit.state';

//	C L A S S

export default class ComVehicleGalleryEditComponent extends React.Component<props___ComVehicleGalleryEditComponent, state___ComVehicleGalleryEditComponent>
{

//#region 																							D E C L A R A T I O N S

	private item___dragged: number | null = null;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComVehicleGalleryEditComponent,
	)
	{
		super(props);
		this.state = state___ComVehicleGalleryEditComponent___defaults;
	}

//#endregion

//#region 																							H A N D L E R S

	handle___gallery_file___onDragStart = (___index: number) => {
		this.item___dragged = ___index;
		this.setState({
			action___item___is_dragging: true
		});
	};

	handle___gallery_file___onDragEnter = (___index: number) => {
		if (this.item___dragged !== null) {
			const ___picture___array = [...this.state.field___gallery_pictures];
			const ___picture = ___picture___array.splice(this.item___dragged, 1)[0];
			___picture___array.splice(___index, 0, ___picture);
			this.setState({
				field___gallery_pictures: ___picture___array
			}, () => {
				this.item___dragged = ___index;
			});
		}
	};

	handle___gallery_file___onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	handle___gallery_file___onDragEnd = () => {
		this.item___dragged = null;
		this.setState({
			action___item___is_dragging: false
		}, () => {
			this.props.event___onUpdate(this.state.field___gallery_pictures);
		});
	};

	handle___gallery_file___remove = (___index: number) => {
		this.setState((___prevState) => ({
			field___gallery_pictures: ___prevState.field___gallery_pictures.filter((_, i) => i !== ___index),
		}), () => {
			this.props.event___onUpdate(this.state.field___gallery_pictures);
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		this.setState({
			field___gallery_pictures: this.props.gallery_pictures
		});
	}

	componentDidUpdate(prevProps: props___ComVehicleGalleryEditComponent) {
		if (prevProps.gallery_pictures !== this.props.gallery_pictures) {
			this.setState({
				field___gallery_pictures: this.props.gallery_pictures
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="vehicle-editor---gallery" style={{ cursor: this.state.action___item___is_dragging ? 'grabbing' : 'grab' }}>
				{this.state.field___gallery_pictures.map((___p: string, ___i: number) => <>
					<div key={ ___i } draggable onDragStart={ () => this.handle___gallery_file___onDragStart(___i) } onDragEnter={ () => this.handle___gallery_file___onDragEnter(___i) } onDragOver={ this.handle___gallery_file___onDragOver } onDragEnd={ this.handle___gallery_file___onDragEnd } >
						<img src={ ___p }/>
						<i className="fas fa-trash vehicle-editor---gallery---remove" onClick={() => { this.handle___gallery_file___remove(___i) }}></i>
					</div>
				</>)}
			</div>
		</>;
	}

//#endregion

}